import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'src/components/shared/Slider'
import PhoneNumber from '../../../../shared/PhoneNumber'
import { useAnalytics } from '../../../../../common/analytics'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useLeadId } from '../../../../../common/lead-id'
import styles from './index.module.scss'
import TrustpilotItem from '../../../../shared/TrusPilotItem'
import { getCookies } from 'src/common/helpers'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'
import AnimatedArrowRedCTA from 'src/components/shared/CallToActionButton/AnimatedArrowRedCTA'

const HeroBannerContent = ({
  content,
  isDefaultLang = true,
  shortView = false,
}) => {
  const {
    title,
    sliderTitle,
    buttonText,
    phoneSpeakText,
    number,
    onSliderChange = () => {},
    defaultDebtAmount,
  } = content
  const { APPLY_FDR_URL } = getConfig()?.publicRuntimeConfig
  const { query: baseQuery } = useRouter()
  const { track, utm_source } = useAnalytics()
  const cookieLeadId = useLeadId()
  const cookies = getCookies()
  const APPLY_URL = isDefaultLang
    ? `${APPLY_FDR_URL}/home/`
    : `${APPLY_FDR_URL}/espanol/`
  const [estimatedDebt, setDebt] = useState(defaultDebtAmount || 25000)
  const { navigateToApply } = useNavigateToApply({
    utm_source,
    estimated_debt: estimatedDebt,
  })

  let query = useMemo(() => {
    const urlParams = {
      from: 'fdr',
      utm_source,
      ...baseQuery,
      estimated_debt: estimatedDebt,
      leadId: cookieLeadId,
      visit_id: cookies?.eh_visit_id || null,
    }

    for (let key in urlParams) {
      if (!urlParams[key]) {
        delete urlParams[key]
      }
    }

    const q = new URLSearchParams(urlParams)

    return q.toString()
  }, [baseQuery, cookieLeadId, cookies?.eh_visit_id, estimatedDebt, utm_source])

  const handleSliderChange = useCallback((event) => {
    const { value } = event.target
    setDebt(value)
    onSliderChange(value)
  }, [])

  const handleSliderClick = useCallback(
    (e) => {
      e.preventDefault()

      const data = {
        nav_link_section: 'Page',
        page_section: sliderTitle,
        click_type: 'Button Click',
        click_id: buttonText,
        click_text: `FDR-Web | ${buttonText}`,
        form_ss_amount: +estimatedDebt,
        track_event: 'button_click',
      }

      if (typeof track === 'function') {
        track(data, {}, 'button_click')
      }

      navigateToApply()
    },
    [sliderTitle, buttonText, estimatedDebt, track, navigateToApply]
  )

  const handleSliderRelease = useCallback(
    (e) => {
      const data = {
        nav_link_section: 'Page',
        page_section: sliderTitle,
        click_type: 'Button Click',
        click_id: 'slider release',
        click_text: `$${e.target.value}`,
        form_ss_amount: +e.target.value,
        track_event: 'button_click',
      }
      if (typeof track === 'function') {
        track(data, {}, 'button_click')
      }
    },
    [track, sliderTitle]
  )

  return (
    <div
      className="flex flex-col md:flex-row xl:justify-center"
      id="hero-content"
    >
      <div className="max-w-full text-center md:w-full md:text-left xl:w-3/4">
        <h1 className="whitespace-pre-wrap text-lg text-blue-610 sm:text-xl lg:mt-2 lg:text-2xl">
          {title}
        </h1>
        <div
          className={`my-3 flex flex-col items-center bg-white py-4 text-center shadow-sm lg:my-4 ${
            shortView ? 'rounded-md' : ''
          }`}
        >
          <h2
            className={`text-2xl font-semibold leading-none ${
              shortView ? 'text-black-base' : 'text-blue-610'
            } `}
          >
            {sliderTitle}
          </h2>
          <Slider
            valueClassName={`my-3 text-lg ${
              shortView ? 'text-blue-610 font-bold' : 'text-gray-900'
            } block`}
            containerClassName="w-4/5 mb-10"
            sliderClassName={styles.slider}
            showMinMaxLabels
            onChange={handleSliderChange}
            start={+estimatedDebt}
            name="estimateSlider"
            step={1000}
            min={1000}
            max={100000}
            unit="$"
            onSliderRelease={handleSliderRelease}
          />
          {shortView && (
            <div className="my-5 w-full bg-green-50 p-5">
              <span>
                You could free up{' '}
                <b className="font-extrabold text-[#046240]">$258/mo</b> 🎉
              </span>
            </div>
          )}
          <AnimatedArrowRedCTA
            buttonText={buttonText}
            url={`${APPLY_URL}?${query}`}
            onClick={handleSliderClick}
            className="w-3/4"
          />
          <p className="mt-3 whitespace-pre-wrap px-3 text-sm text-blue-700 lg:text-xs">
            <PhoneNumber
              href={`tel:${number}`}
              text={number}
              speakText={phoneSpeakText}
              className="block whitespace-nowrap text-xl font-bold text-blue-700 lg:ml-2 lg:inline"
              hasExperiment={true}
              isTrack={true}
              nav_link_section="Slider"
            />
          </p>
          {!shortView && (
            <TrustpilotItem
              starClassName="w-5.5 h-5.5"
              trustScoreClassName="text-base"
              trustpilotClassName="text-base"
              trustpilotRatingClassName="text-base"
              starsGroupClassName="w-20 h-5 mx-2.5"
              className="mt-3 lg:mt-5"
              starsGroupImgClassName="pt-1"
              isHome={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

HeroBannerContent.propTypes = {
  content: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
  shortView: PropTypes.bool,
}
export default HeroBannerContent
